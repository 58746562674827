import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private api: ApiService, private router: Router) {
  }

  public send(email: string, name: string, message: string) {
    this.api.post('contact?name=' + name + '&email=' + email + '&message=' + message, {}).subscribe(data => {
      if (data) {
        this.router.navigate(['contact/thanks']);
      }
    }, error => {
      console.log(error)
    })
  }
}
