import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ToastrModule } from "ngx-toastr";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BannerComponent } from './banner/banner.component'
import { NavHeadComponent } from './nav-head/nav-head.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { NavFooterComponent } from './nav-footer/nav-footer.component';
import { HomeComponent } from './home/home.component';
import { UserSignupComponent } from './user/user-signup/user-signup.component';
import { BeersCatalogComponent } from './beers/beers-catalog/beers-catalog.component';
import { BeersBohemiaPilsnerComponent } from './beers/beers-bohemia-pilsner/beers-bohemia-pilsner.component';
import { BeersBohemiaViennaComponent } from './beers/beers-bohemia-vienna/beers-bohemia-vienna.component';
import { BeersBohemiaWeizenComponent } from './beers/beers-bohemia-weizen/beers-bohemia-weizen.component';
import { HandcraftLifeComponent } from './handcraft/handcraft-life/handcraft-life.component';
import { ContactComponent } from './contact/contact.component';
import { BeersAvailabilityComponent } from './beers/beers-availability/beers-availability.component';
import { TheclanComponent } from './theclan/theclan.component';
import { BeersAvailabilitySectionComponent } from './beers/beers-availability-section/beers-availability-section.component';
import { BeersMeetTheclanComponent } from './beers/beers-meet-theclan/beers-meet-theclan.component';
import { BeersJabaliBockComponent } from './beers/beers-jabali-bock/beers-jabali-bock.component';
import { BeersLagunitasIpaComponent } from './beers/beers-lagunitas-ipa/beers-lagunitas-ipa.component';
import { HandcraftLifeNoteComponent, SafeHtmlPipe } from './handcraft/handcraft-life-note/handcraft-life-note.component';
import { BeersJabaliHellesbockComponent } from './beers/beers-jabali-hellesbock/beers-jabali-hellesbock.component';
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BeersReviewsComponent } from "./beers/beers-reviews/beers-reviews.component";
import { NoticePrivacyComponent } from "./notice-privacy/notice-privacy.component";
import { UserSigninComponent } from "./user/user-signin/user-signin.component";
import { UserFavoritesComponent } from "./user/user-favorites/user-favorites.component";
import { HomePopupnewsComponent } from "./home/home-popupnews/home-popupnews.component";
import { HomeAgeComponent } from "./home/home-age/home-age.component";
import { NoticePrivacyCookiesComponent } from "./notice-privacy/notice-privacy-cookies/notice-privacy-cookies.component";
import { BeersTempusDobleMaltaComponent } from "./beers/beers-tempus-doble-malta/beers-tempus-doble-malta.component";
import { BeersTempusDoradaComponent } from "./beers/beers-tempus-dorada/beers-tempus-dorada.component";
import { BeersShareComponent } from "./beers/beers-share/beers-share.component";
import { ContactThanksComponent } from "./contact/contact-thanks/contact-thanks.component";
import { NgxPrintModule } from 'ngx-print';
import { UserFavoritesItemComponent } from "./user/user-favorites-item/user-favorites-item.component";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { HandcraftLifeRelatedComponent } from "./handcraft/handcraft-life-related/handcraft-life-related.component";
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { ModalModule } from './_modal';
import { TermPrivacyComponent } from './notice-privacy/term-privacy/term-privacy.component';
import { GoogleLoginProvider, FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';


import { environment } from './../environments/environment';
import { RecommendationsComponent } from './recommendations/recommendations.component';
import { EscapeHtmlPipe } from './pipe/keep-html.pipe';


@NgModule({
  declarations: [
    AppComponent,
    EscapeHtmlPipe,
    NavHeadComponent,
    NavHeadComponent,
    NavMenuComponent,
    NavFooterComponent,
    HomeComponent,
    HomeAgeComponent,
    HomePopupnewsComponent,
    TheclanComponent,
    UserSigninComponent,
    UserSignupComponent,
    UserFavoritesComponent,
    UserFavoritesItemComponent,
    BeersCatalogComponent,
    BeersAvailabilityComponent,
    BeersAvailabilitySectionComponent,
    BeersBohemiaPilsnerComponent,
    BeersBohemiaViennaComponent,
    BeersBohemiaWeizenComponent,
    BeersJabaliBockComponent,
    BeersJabaliHellesbockComponent,
    BeersLagunitasIpaComponent,
    BeersTempusDoradaComponent,
    BeersTempusDobleMaltaComponent,
    HandcraftLifeComponent,
    HandcraftLifeNoteComponent,
    HandcraftLifeRelatedComponent,
    ContactComponent,
    ContactThanksComponent,
    BeersAvailabilitySectionComponent,
    BeersMeetTheclanComponent,
    BeersReviewsComponent,
    BeersShareComponent,
    NoticePrivacyComponent,
    NoticePrivacyCookiesComponent,
    SafeHtmlPipe,
    TermPrivacyComponent,
    RecommendationsComponent,
    BannerComponent

  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxPrintModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    ModalModule,
    SocialLoginModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-full-width',
      preventDuplicates: true,
    }),
    GoogleTagManagerModule.forRoot({
      id: 'GTM-M29VX2T',
    })
  ],
  providers: [
    {provide: 'googleTagManagerId', useValue: 'GTM-M29VX2T'},
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              'clientId'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookAppId)
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
