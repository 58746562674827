import {Component, OnInit} from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ApiService } from 'src/services/api.service';

declare let $: any;

@Component({
  selector: 'app-handcraft-life',
  templateUrl: './handcraft-life.component.html',
  styleUrls: ['./handcraft-life.component.css']
})
export class HandcraftLifeComponent implements OnInit {
  articles: any;
  constructor(private api: ApiService) {
  }

  ngOnInit(): void {
    if (($().scrollTop() > 50) || ($('div').hasClass('menunaranja'))) {
      $('.navbar').addClass('scrolled-nav');
    } else {
      $('.navbar').removeClass('scrolled-nav');
    }
    this.getBlog();

  }
  getBlog() {
    this.articles = [];
    this.api.get('blog').subscribe((data: any) => {
      this.articles = data;


    }, error => {
      console.log(error)
    })
  }
}

