import {Component, OnInit} from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
declare let $: any;
@Component({
  selector: 'app-beers-availability',
  templateUrl: './beers-availability.component.html',
  styleUrls: ['./beers-availability.component.css']
})
export class BeersAvailabilityComponent implements OnInit {

  constructor(private dataLayer: GoogleTagManagerService) {
  }

  ngOnInit(): void {
    if (($().scrollTop() > 50) || ($('div').hasClass('menunaranja'))) {
      $('.navbar').addClass('scrolled-nav');
    } else {
      $('.navbar').removeClass('scrolled-nav');
    }
  }

  trackClick(store, type) {
    var dlAnalytics = {
      nameOutboundLinkib2C: store, //Valor dinámico
      nameOutboundLinkTypeib2C: type, //Valor dinámico
      event: "evib2cOutboundlink", //Valor estático
      };
      this.dataLayer.pushTag(dlAnalytics);
  }

}
