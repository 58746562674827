import {Component, OnInit} from '@angular/core';

declare let $: any;

@Component({
  selector: 'app-beers-meet-theclan',
  templateUrl: './beers-meet-theclan.component.html',
  styleUrls: ['./beers-meet-theclan.component.css']
})
export class BeersMeetTheclanComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    $('.carrusel-conoce').slick({
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerMode: true,
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });
  }

}
