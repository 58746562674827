import { ApiService } from 'src/services/api.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.css']
})
export class RecommendationsComponent implements OnInit {
  xeerpaDTS: any;
  constructor(private apiService:  ApiService) { }

  ngOnInit(): void {
    if (($().scrollTop() > 50) || ($('div').hasClass('menunaranja'))) {
      $('.navbar').addClass('scrolled-nav');
    } else {
      $('.navbar').removeClass('scrolled-nav');
    }
    let fid = sessionStorage.getItem('fid');
    let atf = sessionStorage.getItem('atf');
    this.apiService.getRecomnents(fid, atf).subscribe( data => {
      this.xeerpaDTS = data.html;
      console.dir(data);
    });

  }

}
