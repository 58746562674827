import { Component, OnInit } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-beers-bohemia-weizen',
  templateUrl: './beers-bohemia-weizen.component.html',
  styleUrls: ['./beers-bohemia-weizen.component.css']
})
export class BeersBohemiaWeizenComponent implements OnInit {
  BeerId: number = 3;

  constructor(private dataLayer: GoogleTagManagerService) { }

  ngOnInit() {
  }

  trackClick(store, type) {
    var dlAnalytics = {
      nameOutboundLinkib2C: store, //Valor dinámico
      nameOutboundLinkTypeib2C: type, //Valor dinámico
      event: "evib2cOutboundlink", //Valor estático
      };
      this.dataLayer.pushTag(dlAnalytics);
  }
}
