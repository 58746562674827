import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Observable } from 'rxjs';
// import { environment, API_URL} from "../environments/environment";

const SERVER_URL = 'https://elclandelamalta.mx/';
// const SERVER_URL = 'https://prod-ws-elclandelamalta-int-ws-elclandelamalta.azurewebsites.net/';
// const SERVER_URL = 'https://clanmalta.azurewebsites.net/'
// const SERVER_URL = 'https://localhost:5001/'

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private httpClient: HttpClient) {
  }

  public getToken() {
    return sessionStorage.getItem('ClanMaltaStorage.Token')
  }

  public setToken(token: string) {
    sessionStorage.setItem("ClanMaltaStorage.sn", 'email');
    return sessionStorage.setItem("ClanMaltaStorage.Token", token);
  }

  public post(endpoint: string, params: any) {
    return this.httpClient.post(SERVER_URL + 'api/' + endpoint, {params: params});
  }

  public login(endpoint: string, email: string, password: string) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append( 'Cache-Control', 'no-cache');
    headers.append('Accept', 'application/json');

    const body = new FormData();
    body.append('email', email);
    body.append('password', password);

    return this.httpClient.post(SERVER_URL + 'api/' + endpoint, body, { headers });
  }


  public signup(endpoint: string, email: string, password: string, name: string) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append( 'Cache-Control', 'no-cache');
    headers.append('Accept', 'application/json');

    const body = new FormData();
    body.append('email', email);
    body.append('password', password);
    body.append('name', name);

    return this.httpClient.post(SERVER_URL + 'api/' + endpoint, body, { headers });
  }

  get(endpoint: string) {
    return this.httpClient.get(SERVER_URL + 'api/' + endpoint, {});
  }

  getRecomnents(fid, atf) : Observable<any>{
    return this.httpClient.get<any>('https://cuamoc.xeerpa.com:8443/widgets/summary?clientId=5fae84dd49c7140eb4ae4584&clientPwd=2wjcS8LCKL&appId=5fae852349c7140eb4ae495a&userId='+
      fid +'&userToken=' + atf +'&socialNetwork=FB');
  }
}
