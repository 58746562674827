import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-beers-reviews',
  templateUrl: './beers-reviews.component.html',
  styleUrls: ['./beers-reviews.component.css']
})
export class BeersReviewsComponent implements OnInit {
  @Input() Id: number;
  private beer: any;
  private yellowStar: string = 'https://stelclandelamalta.blob.core.windows.net/clanmalta/img/finales/estrella-amarilla.svg';
  private grayStar: string = 'https://stelclandelamalta.blob.core.windows.net/clanmalta/img/finales/estrella-gris.svg';
  private token: string;
  private isFav: boolean = false;
  backgroundImg: string = 'https://stelclandelamalta.blob.core.windows.net/clanmalta/img/finales/favoritos-inactivo.svg';

  score: string[] = [
    this.grayStar,
    this.grayStar,
    this.grayStar,
    this.grayStar,
    this.grayStar,
  ];

  constructor(private api: ApiService, private router: Router) {
  }

  ngOnInit(): void {
    this.token = this.api.getToken();
    this.setCurrentScore();
  }

  vote(number: number) {
    if (this.api.getToken()) {
      this.api.post('score/' + this.beer.id + '/' + number + '/' + this.token, {}).subscribe((data: any) => {
        this.setCurrentScore();
      })
    } else {
      this.router.navigate(['/user/signin'], {queryParams: {from: 'beers/catalog'}});
    }
  }

  private setCurrentScore() {
    this.api.get('beer/' + this.Id + '/show').subscribe((data: any) => {
      this.beer = data;
      this.score.forEach((value, index) => {
        if (index + 1 <= this.beer.score) {
          this.score[index] = this.yellowStar;
        } else {
          this.score[index] = this.grayStar;
        }
      })
    }, error => {
      console.log(error);
    });
  }

  addFavorite() {
    if (this.token) {
      this.api.post('favorites/' + this.api.getToken() + '/' + this.Id, {}).subscribe((data: any) => {
        this.backgroundImg = 'https://stelclandelamalta.blob.core.windows.net/clanmalta/img/finales/favoritos-over.svg';
        this.isFav = true;
      }, error => {
        console.log(error);
      })
    } else {
      this.router.navigate(['/user/signin'], {queryParams: {from: 'beers/catalog'}});
    }
  }

  overFav() {
    this.backgroundImg = 'https://stelclandelamalta.blob.core.windows.net/clanmalta/img/finales/favoritos-over.svg';
  }

  leaveFav() {
    if (!this.isFav) {
      this.backgroundImg = 'https://stelclandelamalta.blob.core.windows.net/clanmalta/img/finales/favoritos-inactivo.svg'
    }
  }
}
