import { Component, OnInit } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-beers-tempus-doble-malta',
  templateUrl: './beers-tempus-doble-malta.component.html',
  styleUrls: ['./beers-tempus-doble-malta.component.css']
})
export class BeersTempusDobleMaltaComponent implements OnInit {
  BeerId: number = 8;

  constructor(private dataLayer: GoogleTagManagerService) { }

  ngOnInit(): void {
  }

  trackClick(store, type) {
    var dlAnalytics = {
      nameOutboundLinkib2C: store, //Valor dinámico
      nameOutboundLinkTypeib2C: type, //Valor dinámico
      event: "evib2cOutboundlink", //Valor estático
      };
      this.dataLayer.pushTag(dlAnalytics);

  }
}
