import {Component, OnInit} from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {ApiService} from "../../services/api.service";

declare let $: any;

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  favoritesNumber: number = 0;
  isFacebook: boolean = false;
  constructor(private api: ApiService, private dataLayer: GoogleTagManagerService) {
  }

  ngOnInit() {
    this.getFavoritesNumber()
    if (this.api.getToken()) {

      $('.menuSignin').hide();
    }
    this.isFacebook = (sessionStorage.getItem('ClanMaltaStorage.sn') === 'facebook');
      if(this.isFacebook){
        $('.menuRecomendations').show();
      } else {
        $('.menuRecomendations').hide();
      }

  }

  private getFavoritesNumber() {
    this.favoritesNumber = 0;
    if (!this.api.getToken()) return;
    this.api.get('favorites/' + this.api.getToken()).subscribe((data: any) => {
      this.favoritesNumber = data.length;
    }, error => {
      console.log('Error ' + error.message);
    })
  }

  trackClicks(event) {
    var dlAnalytics = {
      actionClick:'click_to_' + event,
      event: 'evClickOptions',
      };
      this.dataLayer.pushTag(dlAnalytics);
  }

}
