import { Component, OnInit } from '@angular/core';
import { ApiService } from "../services/api.service";
import { Router } from "@angular/router";
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ModalService } from './_modal';
import { PrivacyService } from 'src/services/privacy.service';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  privacy: any;
  privac: any;
  title = 'El Clan de la Malta';
  favoritesNumber: number = 0;

  constructor(private api: ApiService,
  
    private gtmService: GoogleTagManagerService,
    private modalService: ModalService, private privacyService: PrivacyService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.checkToken();
    this.gtmService.addGtmToDom();
    $("#menu-sitio").show();
    $("footer").show();
    
     $(".navbar-toggler").click(function () {
      $("#menu-sitio").toggleClass("menu-abierto");
      $('.responsive').slick({
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false
      });
    });

  }
  
  private checkToken() {
    if (!sessionStorage.getItem('ClanMaltaStorage.Age') && !this.api.getToken()) {
      this.router.navigate(['/age']);
    }
  }

}
