import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ApiService} from "../../../services/api.service";
import {DomSanitizer} from "@angular/platform-browser";
import { GoogleTagManagerService } from 'angular-google-tag-manager';

declare let $: any;

@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-handcraft-life-note',
  templateUrl: './handcraft-life-note.component.html',
  styleUrls: ['./handcraft-life-note.component.css']
})
export class HandcraftLifeNoteComponent implements OnInit {
  id: any;
  article: any;
  articlesRelated: any;

  slideConfig = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
};


  constructor(private route: ActivatedRoute, private api: ApiService, private dataLayer: GoogleTagManagerService) {
    this.articlesRelated = [];
    this.article = [];
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    if (($().scrollTop() > 50) || ($('div').hasClass('menunaranja'))) {
      $('.navbar').addClass('scrolled-nav');
    } else {
      $('.navbar').removeClass('scrolled-nav');
    }
    this.getArticle(this.id);
    this.getRelated(this.id);
  }

  getArticle(id) {
    this.article = [];
    this.api.get('blog/' + this.id + '/show').subscribe((data: any) => {
      this.article = data;
      var dlAnalytics = {
        articleName: data.slug,
        articleCategory:'Vida Artesanal',
        event: "evArticleEvent",
      };
      this.dataLayer.pushTag(dlAnalytics);
    }, error => {
      console.log(error)
    });
  }

  getRelated(id) {
    this.articlesRelated = [];
    this.api.get('blog/' + id + '/related').subscribe((data: any) => {
      this.articlesRelated = data;
    }, error => {
      console.log(error)
    })
  }
}
