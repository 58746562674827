import {Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { PrivacyService } from 'src/services/privacy.service';
import { ModalService } from '../_modal';
// import { GoogleTagManagerService } from 'angular-google-tag-manager';
// import { ToastrService } from 'ngx-toastr';
// import {ContactService} from "../../services/contact.service";

declare let $: any;

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  botonCerrarCookies: any
  avisoCookies: any
  routerEvent: any;
  booleanVar: boolean = true;
  



  privacy: any;
  privac: any;
  privaterms: any

  constructor(
    private privacyService: PrivacyService,
    private modalService: ModalService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    
    this.privacyService.getPrivacyPolicy().subscribe(data => {
      this.privacy = data.replaceAll("font-size:11.0pt", 'font-size: 21px').replaceAll("text-align:justify",'text-align:center');
    });
    
    this.privacyService.getPrivacyCookies().subscribe(data => {
      this.privac = data;
    });

    this.privacyService.getPrivacyterms().subscribe(data => {
      this.privaterms = data;
    });

    this.routerEvent = this.router.events.subscribe((res:any) => {
      if((res instanceof NavigationStart)){
        if(res.url === '/age'){
          this.booleanVar = false;
        }else{
          this.booleanVar = true;
        }
        console.log(this.booleanVar)
      }
    })
   
      this.getElements();
      this.localStorageBannerVerification();
   }

   getElements() {
    this.botonCerrarCookies = document.getElementById('btn-cerrar-cookies');
    this.avisoCookies = document.getElementById('aviso-cookies');
    console.log("aviso", this.avisoCookies)
  }

  newFunction(url:string, event = null, modal){
    console.log(this.router.url)
     if(this.router.url !== "/age"){
       this.router.navigateByUrl(url)
     }else{
       this.openModal(event, modal)
     }
  }

  localStorageBannerVerification() {
    if (!localStorage.getItem('cookies-aceptadas')) {
      this.avisoCookies.classList.add('activo');
    }
    console.log(localStorage.getItem('cookies-aceptadas'))
  }

  button_BanerFunction() {
      this.avisoCookies.classList.remove('activo');
      localStorage.setItem('cookies-aceptadas', String(true));
  }

  openModal(event, id: string) {
    event.preventDefault();
    this.modalService.open(id);
  }
  
  closeModal(id: string) {
    this.modalService.close(id);
  }

}
