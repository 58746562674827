import {Component, OnInit} from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ToastrService } from 'ngx-toastr';
import {ContactService} from "../../services/contact.service";

declare let $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  name: string;
  email: string;
  message: string;

  status: boolean = true;
  constructor(private contact: ContactService,
    private toast: ToastrService,
    private dataLayer: GoogleTagManagerService) {
  }

  ngOnInit(): void {
    $('.responsive2').slick({
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: false
    });
    $('#footer').hide();
  }

  sedMessage() {
    if (this.status && this.email.includes('@') && this.name !== null, this.message !== null) {
      var dlAnalytics = {
        action:'Exito',
        error:'ND',
        event: "evContactForm", //Valor estático
        };
        this.dataLayer.pushTag(dlAnalytics);
      this.contact.send(this.email, this.name, this.message);
      }
      else {
        var dlAnalytics = {
          action:'Exito',
          error:'Es necesario llenar todos los campos',
          event: "evContactForm", //Valor estático
          };
          this.dataLayer.pushTag(dlAnalytics);
          this.toast.error('Es necesario llenar todos los campos');
      }
  }

  checkbox() {
    this.status = !this.status;
  }
}
