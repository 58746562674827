import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {Router} from "@angular/router";
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-signup',
  templateUrl: './user-signup.component.html',
  styleUrls: ['./user-signup.component.css']
})
export class UserSignupComponent implements OnInit {
  name: string;
  email: string;
  password: string;

  constructor(private api: ApiService,
              private dataLayer: GoogleTagManagerService,
              private toast: ToastrService,
              private router: Router) {
  }

  ngOnInit() {
  }

  createAccount() {
    if (this.name && this.email && this.password) {
      this.api.signup('user/local/new', this.email, this.password, this.name).subscribe((data: any) => {
        this.api.setToken(data.key);
        var dlAnalytics = {
          action:'Exito',
          error: 'NA',
          event: "evSignUp",
        };
        this.dataLayer.pushTag(dlAnalytics);
        this.router.navigate(['/']);
      }, error => {
        var dlAnalytics = {
          action:'Error',
          error: error,
          event: "evSignUp",
        };
        this.dataLayer.pushTag(dlAnalytics);
        this.toast.error('Por el momento no es posible crear la cuenta, intente mas tarde :' + error);
      });
    }
    else {
      var dlAnalytics = {
        action:'Error',
        error: 'Es necesario llenar todos los campos',
        event: "evSignUp", //Valor estático
        };
        this.dataLayer.pushTag(dlAnalytics);
        this.toast.error('Para crear una cuenta es necesario llenar los campos');
    }
  }
}
