import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ToastrService } from 'ngx-toastr';
import {ApiService} from "../../services/api.service";
import { ModalService } from '../_modal';


declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  botonCerrarCookies: any;
  avisoCookies: any;
  isChecked: boolean = false;
  email: string = "";

  constructor(private router: Router,
              private toast: ToastrService,
              private dataLayer: GoogleTagManagerService,
              private api: ApiService,
              private modalService: ModalService) {
  }

  ngOnInit(): void {

    this.localStorageBannerVerification();

    $('.carrusel-home').slick({
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerMode: true,
          }
        }
      ]
    });
  }


  getElements() {
    console.log('hola')
    this.botonCerrarCookies = document.getElementById('btn-cerrar-cookies');
    this.avisoCookies = document.getElementById('cookiess');
  }

  localStorageBannerVerification() {
    if (!localStorage.getItem('cookies-aceptadas')) {
      this.avisoCookies.classList.add('activo');
      console.log('verificacion')
    }
  }

  addNewsLatter(): void {
    if (this.isChecked && this.email.includes('@')) {
      this.api.post('newsletter?email=' + this.email + '&name=NA&message=NA', {}).subscribe((data: any) => {
        var dlAnalytics = {
          action:'Exito',
          error:'NA',
          event: "evNewsLetterForm",
          };
          this.dataLayer.pushTag(dlAnalytics);
        this.router.navigate(['/newslatter'], {fragment: 'popupnews', queryParams: {email: this.email, id: data.Id}});
      }, error => {
        var dlAnalytics = {
          action:'Error',
          error: error,
          event: "evNewsLetterForm",
          };
          this.dataLayer.pushTag(dlAnalytics);
        this.toast.error('Es necesario llenar todos los campos');
      })
    }else {
      var dlAnalytics = {
        action:'Error',
        error:'Es necesario ingresar un correo electónico valido',
        event: "evNewsLetterForm",
        };
        this.dataLayer.pushTag(dlAnalytics);
      this.toast.error('Es necesario ingresar un correo electónico valido y aceptar los términos y condiciones');
    }

  }
}
