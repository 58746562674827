import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-theclan',
  templateUrl: './theclan.component.html',
  styleUrls: ['./theclan.component.css']
})
export class TheclanComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }


}
