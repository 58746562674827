import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { PrivacyService } from 'src/services/privacy.service';
import {DomSanitizer} from "@angular/platform-browser";

declare let $: any;

@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-notice-privacy',
  templateUrl: './notice-privacy.component.html',
  styleUrls: ['./notice-privacy.component.css']
})

export class NoticePrivacyComponent implements OnInit {
  privacy: any;
  ageValidate: any

  constructor(private privacyService: PrivacyService) { }

  ngOnInit(): void {
    this.ageValidate = sessionStorage.getItem('ClanMaltaStorage.Age')
    if (this.ageValidate == 'false') {
      $("#menu-sitio").hide();
      $("footer").hide();
    }


    this.privacyService.getPrivacyPolicy().subscribe(data => {
      this.privacy = data.replaceAll("font-size:11.0pt", 'font-size: 21px').replaceAll("text-align: center", 'text-align:start');
    });
    $("#aviso-cookies").hide();
  }
  
}
