import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";

import { sha256, sha224 } from 'js-sha256';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-user-signin',
  templateUrl: './user-signin.component.html',
  styleUrls: ['./user-signin.component.css']
})
export class UserSigninComponent implements OnInit {
  private from: string = '/'
  password: string;
  email: string;
  constructor(private api: ApiService,
              private dataLayer: GoogleTagManagerService,
              private toast: ToastrService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.from = params['from'] ? params['from'] : '/'
    })
    this.api.get('session/' + this.api.getToken()).subscribe(data => {
      if (data)
        this.router.navigate([this.from]);
    });
    window.addEventListener('storage', () => {
      this.router.navigate([this.from]);
    })
  }

  login() {
    if (this.email && this.email && this.password) {
      this.api.login('user/local', this.email, this.password).subscribe((data: any) => {
        var dlAnalytics = {
          action:'Exito',
          error:'NA',
          event: "evLogin", //Valor estático
          };
          this.dataLayer.pushTag(dlAnalytics);
        sessionStorage.setItem('ClanMaltaStorage.email', this.email);
        this.api.setToken(data.key);
        this.router.navigate(['/']);
      }, error => {
        var dlAnalytics = {
          action:'Error',
          error: error,
          event: "evLogin", //Valor estático
          };
          this.dataLayer.pushTag(dlAnalytics);
          this.toast.error('Por el momento no es posible ingresar a la cuenta, intente mas tarde :' + error);
      });
    }
    else{
    var dlAnalytics = {
      action:'Error',
      error: 'Es necesario llenar todos los campos',
      event: "evLogin", //Valor estático
      };
      this.dataLayer.pushTag(dlAnalytics);
      this.toast.error('Para ingresar a la cuenta es necesario llenar los campos');
    }
  }
}
