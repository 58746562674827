import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { PrivacyService } from 'src/services/privacy.service';
import {DomSanitizer} from "@angular/platform-browser";

declare let $: any;
@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
@Component({
  selector: 'app-term-privacy',
  templateUrl: './term-privacy.component.html',
  styleUrls: ['./term-privacy.component.css']
})
export class TermPrivacyComponent implements OnInit {
  privaterms: any;

  constructor(private privacyService: PrivacyService) { }

  ngOnInit(): void {
    this.privacyService.getPrivacyterms().subscribe(data => {
      this.privaterms = data.replaceAll("text-align: center", 'text-align:start');
      
    });
    $("#aviso-cookies").hide();
  }

}
