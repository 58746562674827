import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {Router} from "@angular/router";

declare let $: any;

@Component({
  selector: 'app-user-favorites',
  templateUrl: './user-favorites.component.html',
  styleUrls: ['./user-favorites.component.css']
})
export class UserFavoritesComponent implements OnInit {
  favorites: any;

  constructor(private api: ApiService, private router: Router) {
  }

  ngOnInit(): void {
    if (!this.api.getToken()) {
      this.router.navigate(['user/signin'])
    } else {
      if (($().scrollTop() > 50) || ($('div').hasClass('menunaranja'))) {
        $('.navbar').addClass('scrolled-nav');
      } else {
        $('.navbar').removeClass('scrolled-nav');
      }

      // TODO: extraer todas las cervezas en favoritos para el usuario
      this.getFavorites();
    }

  }

  private getFavorites() {
    this.favorites = [];
    this.api.get('favorites/' + this.api.getToken()).subscribe((data: any) => {
      this.favorites = data;
    }, error => {
      console.log(error)
    })
  }
}
