import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

declare let $: any;

@Component({
  selector: 'app-home-popupnews',
  templateUrl: './home-popupnews.component.html',
  styleUrls: ['./home-popupnews.component.css']
})
export class HomePopupnewsComponent implements OnInit {
  userEmail: string;
  userNumber: number;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    $('.carrusel-home').slick({
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerMode: true,
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.userEmail = params['email']
      this.userNumber = params['id']
    })
  }

}
