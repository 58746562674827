import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";

class Beer {
  fotoURL: string;
  id: number;
  nombre: string;
  alias: string;
  descripcion: string;
}

@Component({
  selector: 'app-user-favorites-item',
  templateUrl: './user-favorites-item.component.html',
  styleUrls: ['./user-favorites-item.component.css']
})
export class UserFavoritesItemComponent implements OnInit {
  @Input() Id: number;
  theBeer: Beer = new Beer();

    beers: {
      pilsner: {
        vol: '',
        amazon: '',
        mercado: '',
        superama: '',
        walmart: '',
        corner: '',
        six: ''
      },
      vienna: {
        vol: '',
        amazon: '',
        mercado: '',
        superama: '',
        walmart: '',
        corner: '',
        six: ''
      },
      weizen: {
        vol: '',
        amazon: '',
        mercado: '',
        superama: '',
        walmart: '',
        corner: '',
        six: ''
      },
      ipa: {
        vol: '',
        amazon: '',
        mercado: '',
        superama: '',
        walmart: '',
        corner: '',
        six: ''
      },
      bock : {
        vol: '',
        amazon: '',
        mercado: '',
        superama: '',
        walmart: '',
        corner: '',
        six: ''
      },
      hellesbock: {
        vol: '',
        amazon: '',
        mercado: '',
        superama: '',
        walmart: '',
        corner: '',
        six: ''
      },
      dorada: {
        vol: '',
        amazon: '',
        mercado: '',
        superama: '',
        walmart: '',
        corner: '',
        six: ''
      },
      doble : {
        vol: '',
        amazon: '',
        mercado: '',
        superama: '',
        walmart: '',
        corner: '',
        six: ''
      }

  };
  constructor(private api: ApiService) {
  }

  ngOnInit(): void {
    this.api.get('beer/' + this.Id + '/show').subscribe((data: Beer)=>{
      console.log(data);
      this.theBeer = data;
    }, error => {
      console.log(error);
    })
  }

  removeFavorite() {

  }
}
