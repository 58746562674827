
import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { PrivacyService } from 'src/services/privacy.service';
import { DomSanitizer } from "@angular/platform-browser";

declare let $: any;


Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-notice-privacy-cookies',
  templateUrl: './notice-privacy-cookies.component.html',
  styleUrls: ['./notice-privacy-cookies.component.css']
})
export class NoticePrivacyCookiesComponent implements OnInit {

  ageValidate: any

  privac: any;
  constructor(private privacyService: PrivacyService) { }

  ngOnInit(): void {

    this.ageValidate = sessionStorage.getItem('ClanMaltaStorage.Age')
    if (this.ageValidate == 'false') {
      $("#menu-sitio").hide();
      $("footer").hide();
    }

    this.privacyService.getPrivacyCookies().subscribe(data => {
      this.privac = data;
    });

    $("#aviso-cookies").hide();


  }



}
