import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class PrivacyService {

  constructor(private httpClient: HttpClient) {
  }

  getPrivacyPolicy(): Observable<any> {
    return this.httpClient.get('https://api-mx-marketing.azure-api.net/sitios/privacidad/aviso', {responseType: 'text'})
        .pipe(map((res) => {
            return res.replace('{', '').replace('"body":"', '').replace('";', '').replace('}','').replace('}','').replace('}','')
                  
                    .slice(0, -3);
        }));
    }
    getPrivacyCookies(): Observable<any> {
      return this.httpClient.get('https://api-mx-marketing.azure-api.net/sitios/cookies/aviso/V2', {responseType: 'text'})
          .pipe(map((res) => {
              return res.replace('{', '').replace('"body":"', '').replace('";', '').replace('}','').replace('}','')
                      .slice(0, -3);
          }));
      }
      getPrivacyterms(): Observable<any> {

        const headers= new HttpHeaders({
          'campaign': 'general'
        })

        return this.httpClient.get('https://api-mx-marketing.azure-api.net/sitios/tycos?type=2',{headers, responseType:'text'}).pipe(map((res) => {
                  return res.replace('{', '').replace('"body":"', '').replace('";', '').replace('}','').replace('}','').replace('[[urlsitio]]', 'https://elclandelamalta.mx')
                  .slice(0, -3);
              }));
      
        }
}
