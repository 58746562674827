import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {UserSigninComponent} from './user/user-signin/user-signin.component';
import {UserSignupComponent} from './user/user-signup/user-signup.component';
import {BeersCatalogComponent} from './beers/beers-catalog/beers-catalog.component';
import {BeersBohemiaPilsnerComponent} from './beers/beers-bohemia-pilsner/beers-bohemia-pilsner.component';
import {BeersBohemiaViennaComponent} from './beers/beers-bohemia-vienna/beers-bohemia-vienna.component';
import {BeersBohemiaWeizenComponent} from './beers/beers-bohemia-weizen/beers-bohemia-weizen.component';
import {HandcraftLifeComponent} from './handcraft/handcraft-life/handcraft-life.component';
import {ContactComponent} from './contact/contact.component';
import {BeersAvailabilityComponent} from './beers/beers-availability/beers-availability.component';
import {TheclanComponent} from './theclan/theclan.component';
import {BeersLagunitasIpaComponent} from './beers/beers-lagunitas-ipa/beers-lagunitas-ipa.component';
import {BeersJabaliBockComponent} from './beers/beers-jabali-bock/beers-jabali-bock.component';
import {HandcraftLifeNoteComponent} from './handcraft/handcraft-life-note/handcraft-life-note.component';
import {BeersJabaliHellesbockComponent} from './beers/beers-jabali-hellesbock/beers-jabali-hellesbock.component';
import {HomePopupnewsComponent} from './home/home-popupnews/home-popupnews.component';
import {NoticePrivacyComponent} from "./notice-privacy/notice-privacy.component";
import {UserFavoritesComponent} from "./user/user-favorites/user-favorites.component";
import {HomeAgeComponent} from "./home/home-age/home-age.component";
import {NoticePrivacyCookiesComponent} from "./notice-privacy/notice-privacy-cookies/notice-privacy-cookies.component";
import {BeersTempusDoradaComponent} from "./beers/beers-tempus-dorada/beers-tempus-dorada.component";
import {BeersTempusDobleMaltaComponent} from "./beers/beers-tempus-doble-malta/beers-tempus-doble-malta.component";
import {ContactThanksComponent} from "./contact/contact-thanks/contact-thanks.component";
import { TermPrivacyComponent } from './notice-privacy/term-privacy/term-privacy.component';
import { RecommendationsComponent } from './recommendations/recommendations.component';

import { LogGuard } from './guard/log.guard';

const routes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full', canActivate: [LogGuard], data: {title: 'Home'}},
  {path: 'newslatter', component: HomePopupnewsComponent, canActivate: [LogGuard], data: {title: 'Newsletter'}},
  {path: 'age', component: HomeAgeComponent, canActivate: [LogGuard]},
  {
    path: 'user', children: [
      {path: 'signin', component: UserSigninComponent, canActivate: [LogGuard], data: {title: 'Signin'}},
      {path: 'signup', component: UserSignupComponent, canActivate: [LogGuard], data: {title: 'Signup'}},
      {path: 'favorites', component: UserFavoritesComponent, canActivate: [LogGuard], data: {title: 'Favorites'}},
    ]
  },
  {
    path: 'theclan', component: TheclanComponent, canActivate: [LogGuard], data: {title: 'The clan'}
  },
  {
    path: 'recommendations', component: RecommendationsComponent, canActivate: [LogGuard], data: {title: 'Recomendations'}
  },
  {
    path: 'contact', children: [
      {path: '', component: ContactComponent, canActivate: [LogGuard], data: {title: 'Contact'}},
      {path: 'thanks', component: ContactThanksComponent, canActivate: [LogGuard], data: {title: 'Thanks'}}
    ]
  },
  {
    path: 'beers', children: [
      {path: 'catalog', component: BeersCatalogComponent, canActivate: [LogGuard], data: {title: 'Beers'}},
      {path: 'availability', component: BeersAvailabilityComponent, canActivate: [LogGuard], data: {title: 'Availability'}},
      {path: 'bohemia-pilsner', component: BeersBohemiaPilsnerComponent, canActivate: [LogGuard], data: {title: 'Bohemia Pilsner'}},
      {path: 'bohemia-vienna', component: BeersBohemiaViennaComponent, canActivate: [LogGuard], data: {title: 'Bohemia Vienna'}},
      {path: 'bohemia-weizen', component: BeersBohemiaWeizenComponent, canActivate: [LogGuard], data: {title: 'Boemia Weizen'}},
      {path: 'lagunitas-ipa', component: BeersLagunitasIpaComponent, canActivate: [LogGuard], data: {title: 'Lagunitas IPA'}},
      {path: 'jabali-bock', component: BeersJabaliBockComponent, canActivate: [LogGuard], data: {title: 'Jabali Bock'}},
      {path: 'jabali-hellesbock', component: BeersJabaliHellesbockComponent, canActivate: [LogGuard], data: {title: 'Jabali Hellesbock'}},
      {path: 'tempus-dorada', component: BeersTempusDoradaComponent, canActivate: [LogGuard], data: {title: 'Tempus Dorada'}},
      {path: 'tempus-doble-malta', component: BeersTempusDobleMaltaComponent, canActivate: [LogGuard], data: {title: 'Tempus Doble Malta'}},
    ]
  },
  {
    path: 'handcraft', children: [
      {path: 'life', component: HandcraftLifeComponent, canActivate: [LogGuard], data: {title: 'Handcraft'}},
      {path: 'life/note/:id/:slug', component: HandcraftLifeNoteComponent, canActivate: [LogGuard], data: {title: 'Handcrafy note'}},
    ]
  },
  {
    path: 'notice-privacy', children: [
      {path: '', component: NoticePrivacyComponent, canActivate: [LogGuard], data: {title: 'Notice privacy'}},
      {path: 'cookies', component: NoticePrivacyCookiesComponent, canActivate: [LogGuard], data: {title: 'Cookies'}},
      {path: 'terms', component: TermPrivacyComponent, canActivate: [LogGuard], data: {title: 'Terms'}}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
