import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-beers-availability-section',
  templateUrl: './beers-availability-section.component.html',
  styleUrls: ['./beers-availability-section.component.css']
})
export class BeersAvailabilitySectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
