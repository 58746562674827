import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { sha256 } from 'js-sha256';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogGuard implements CanActivate {
  logAnalitics: any;
  constructor(private router: Router, private dataLayer: GoogleTagManagerService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.log(route, state);
      this.dataLayer.pushTag(this.logAnalitics);
    return true;
  }

  log(route, state){
    var dateLog = new Date();
    var productInfo = {}, articleInfo = {};
    if (state.url.indexOf('beers') !== -1 && state.url.indexOf('catalog') < 0
        && state.url.indexOf('availability') < 0 ) {
      console.log('catalog de cervezas');
      productInfo = {
        productName: route.data.title, //dynamic value
        productCategory:'Beers' , //dynamic value
        productRaiting:'ND', //dynamic value
        tagCategory:'ND' //dynamic value
        }
    }
    if(state.url.indexOf('note') != -1) {
      var n = state.url.lastIndexOf('/');
      var result = state.url.substring(n + 1);
      result = result.split('-').join(' ');
      articleInfo = {
          articleName: result, //dynamic value
          articleCategory:'Vida Artesanal', //dynamic value
          articleWithProduct:'ND', //dynamic value
          }
      }


    var sha256Email = sessionStorage.getItem('ClanMaltaStorage.email')=== null ? 'ND' : sha256(sessionStorage.getItem('ClanMaltaStorage.email'));
    this.logAnalitics = {
      event: 'evPageView', //static value
      version: '1.0' , //static value
      page: {
        pageInfo: {
          hostName: 'https://www.elclandelamalta.mx/',
          pageName: route.data.title,
          currentURL: 'https://elclandelamalta.mx' + state.url,
        },
      },
      user: {
        profile: {
          userWeekDay: dateLog.getDay(),
          userMonthDay: dateLog.getDate() ,
          userMonth: dateLog.getMonth() + 1,
          userTime: dateLog.getHours() + 1,
          languajeUser:'ND',
          statusLogged: sessionStorage.getItem('ClanMaltaStorage.sn') == null ? 'Not Logged' :'Logged',
          profileInfo: {
            ageUserBirthYear: sessionStorage.getItem('ClanMaltaStorage.birthdate'),
            ageUser: sessionStorage.getItem('ClanMaltaStorage.ageNum'),
            ageUserCountry:'ND',
            userId: sha256Email,
            email: sha256Email,
        },
        social: {
          network: sessionStorage.getItem('ClanMaltaStorage.sn') == null ? 'ND' : sessionStorage.getItem('ClanMaltaStorage.sn'),
            }
          }
      },
      products:[{
          productInfo : productInfo
        }],
      articles:[{
            articleInfo: articleInfo
          }]
        };
    }
  }
