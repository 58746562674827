import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-beers-share',
  templateUrl: './beers-share.component.html',
  styleUrls: ['./beers-share.component.css']
})
export class BeersShareComponent implements OnInit {
  twLink: string = 'https://twitter.com/intent/tweet?url=';
  fbLink: string = 'https://www.facebook.com/sharer/sharer.php?u=';

  constructor(private route: Router, private dataLayer: GoogleTagManagerService) {
  }

  ngOnInit(): void {
    this.fbLink = this.fbLink + window.location.href;
    this.twLink = this.twLink + window.location.href + '&text=El Clan de la Malta';
  }

  trackClick(s) {
    var dlAnalytics = {
      socialNetworkName:s, //Valor dinámico
      actionSocialNetwork: (s == 'twitter') ? this.twLink : this.fbLink, //Valor dinámico
      event: "evSocialNetwork", //Valor estático
      };
      this.dataLayer.pushTag(dlAnalytics);

  }
}
