import {Component, OnInit} from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {ApiService} from "../../../services/api.service";

declare let $: any;

@Component({
  selector: 'app-beers-bohemia-pilsner',
  templateUrl: './beers-bohemia-pilsner.component.html',
  styleUrls: ['./beers-bohemia-pilsner.component.css']
})
export class BeersBohemiaPilsnerComponent implements OnInit {
  BeerId: number = 1;
  constructor(private api: ApiService, private dataLayer: GoogleTagManagerService) {
  }

  ngOnInit(): void {
  }

  trackClick(store, type) {
    var dlAnalytics = {
      nameOutboundLinkib2C: store, //Valor dinámico
      nameOutboundLinkTypeib2C: type, //Valor dinámico
      event: "evib2cOutboundlink", //Valor estático
      };
      this.dataLayer.pushTag(dlAnalytics);

  }
}
