import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {ContactService} from "../../services/contact.service";
import {ToastrService} from "ngx-toastr";
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-nav-footer',
  templateUrl: './nav-footer.component.html',
  styleUrls: ['./nav-footer.component.css']
})
export class NavFooterComponent implements OnInit {
  contactForm: FormGroup;
  isChecked: boolean;
  status: boolean = true;
  constructor(private contact: ContactService,
              private toast: ToastrService,
              private dataLayer: GoogleTagManagerService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.contactForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      message: new FormControl('',[Validators.required]),
    });
  }

  sendMessage() {
    if (this.contactForm.valid) {
     this.contact.send(this.contactForm.value.email, this.contactForm.value.name, this.contactForm.value.message);
     var dlAnalytics = {
      action:'Exito',
      error:'NA',
      event: "evContactForm",
      };
      this.dataLayer.pushTag(dlAnalytics);

    }
    else {
      var dlAnalytics = {
        action:'Error',
        error:'Es necesario llenar todos los campos',
        event: "evContactForm",
        };
        this.dataLayer.pushTag(dlAnalytics);
      this.toast.error('Es necesario llenar todos los campos');
    }
  }

  checked (){
    console.log(this.status);
    this.status = !this.status;
  }
}
