import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { SocialAuthService } from 'angularx-social-login';
import { PrivacyService } from 'src/services/privacy.service';
import { ModalService } from '../../_modal';


declare let $: any;

@Component({
  selector: 'app-home-age',
  templateUrl: './home-age.component.html',
  styleUrls: ['./home-age.component.css']
})
export class HomeAgeComponent implements OnInit {
  botonCerrarCookies: any;
  avisoCookies: any;

  privacidad: any;
  privac: any;
  privaterms: any;
  day: number;
  month: number;
  year: number;
  country: string = '';

  private birthdate: Date;

  constructor(private router: Router,
    private modalService: ModalService,
    private socialAuthService: SocialAuthService,
    private gtmService: GoogleTagManagerService,
    private privacyService: PrivacyService) {
  }

  openModal(event, id: string) {
    event.preventDefault();
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  ngOnInit(): void {
    
    // this.getElements();

    // this.localStorageBannerVerification();

    this.privacyService.getPrivacyPolicy().subscribe(data => {
      // este servicio lo esta tomando desde el BannerComponent.ts
      // this.privacidad = data.replaceAll("text-align:justify",'text-align:center') +"pruebas";
      // console.log("data", this.privacidad)
    }); 
    this.privacyService.getPrivacyCookies().subscribe(data => {
      this.privac = data
    });
    this.privacyService.getPrivacyterms().subscribe(data => {
      this.privaterms = data
    });
  
    $("#menu-sitio").hide();
    $("footer").hide();
    //$("#aviso-cookies").hide();
    //$("#cookiess").show();

    window.addEventListener('storage', () => {
      this.router.navigate(['/']);
    })
  }
  // getElements() {
  //   console.log('hola')
  //   this.botonCerrarCookies = document.getElementById('btn-cerrar-cookies');
  //   this.avisoCookies = document.getElementById('cookiess');
  // }

  // localStorageBannerVerification() {
  //   if (!localStorage.getItem('cookies-aceptadas')) {
  //     this.avisoCookies.classList.add('activo');
  //     console.log('verificacion')
  //   }
  // }

  
//   button_BanerFunction() {
//     console.log("hola armando al pueblo")
//     this.avisoCookies.classList.remove('activo');
//     localStorage.setItem('cookies-aceptadas', String(true));
// }

  validateAge() {
    let dateString = this.year + '-' + (this.month < 10 ? '0' + this.month : this.month) + '-' + this.day;
    this.birthdate = new Date(dateString);
    if (this.birthdate) {
      sessionStorage.setItem('ClanMaltaStorage.birthdate', this.birthdate.toISOString().slice(0, 10));
      // @ts-ignore
      let timeDiff = Math.abs(Date.now() - this.birthdate);
      if (Math.floor((timeDiff / (1000 * 3600 * 24)) / 365) >= 18) {
        let agenum = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365) + "";
        sessionStorage.setItem('ClanMaltaStorage.ageNum', agenum);
        var dlAnalytics = {
          action: 'Exito',
          error: 'NA',
          event: "evAgeGate",    //Valor estático
        };
        this.gtmService.pushTag(dlAnalytics);

        $("#menu-sitio").show();
        $("footer").show();
        // $("#aviso-cookies").show();
        // $("#cookies").hide();

        sessionStorage.setItem('ClanMaltaStorage.Age', String(true));
        this.router.navigate(['/']);
      }
    }
  }

  nextField($event: KeyboardEvent) {
    const input = $event.target;
    if (input["value"].length == input["max"]) {
      let next = input["tabIndex"] + 1;
      let element = document.querySelector("input[tabIndex='" + (next > 4 ? 1 : next) + "']");
      // @ts-ignore
      element.focus();
    }
  }
}
