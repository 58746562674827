import { Component, OnInit } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-contact-thanks',
  templateUrl: './contact-thanks.component.html',
  styleUrls: ['./contact-thanks.component.css']
})
export class ContactThanksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if (($().scrollTop() > 50) || ($('div').hasClass('menunaranja'))) {
      $('.navbar').addClass('scrolled-nav');
    } else {
      $('.navbar').removeClass('scrolled-nav');
    }
  }

}
