import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-beers-catalog',
  templateUrl: './beers-catalog.component.html',
  styleUrls: ['./beers-catalog.component.css']
})
export class BeersCatalogComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
